import React from 'react';
import { graphql } from 'gatsby';
import { mergePrismicPreviewData } from 'gatsby-source-prismic';
import { Layout, Slice } from 'components';

const IS_BROWSER = typeof window !== 'undefined';

export const ServiceCategory = ({ location, data: staticData }) => {
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__;
  const data = mergePrismicPreviewData({ staticData, previewData });
  const { prismicServiceCategory } = data;
  const { data: pageData } = prismicServiceCategory;
  const {
    body: sliceData,
    meta_title: metaTitle,
    meta_description: metaDesc,
    open_graph_image: ogImage,
    schema,
  } = pageData;
  const seo = {
    title: metaTitle?.text,
    desc: metaDesc?.text,
    banner: ogImage?.localFile?.childImageSharp?.fixed?.src,
    schema: schema?.text,
  };

  return (
    <Layout location={location} seo={seo}>
      {sliceData?.map(slice => (
        <Slice key={slice.id} data={slice} />
      ))}
    </Layout>
  );
};

export default ServiceCategory;

export const serviceQuery = graphql`
  query ServiceCategyBySlug($uid: String!) {
    prismicServiceCategory(uid: { eq: $uid }) {
      id
      uid
      prismicId
      data {
        title {
          text
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url
        }
        schema {
          text
        }
        body {
          ...PrismicServiceCategoryBodyVideoFragment
          ...PrismicServiceCategoryBodyHeadingFragment
          ...PrismicServiceCategoryBodyBannerFragment
          ...PrismicServiceCategoryBodyFeaturedServicesFragment
          ...PrismicServiceCategoryBodyFeaturedContentFragment
          ...PrismicServiceCategoryBodyQuoteFragment
          ...PrismicServiceCategoryBodyFeaturedProjectsFragment
          ...PrismicServiceCategoryBodyFeaturedArticlesFragment
          ...PrismicServiceCategoryBodyTextFragment
          ...PrismicServiceCategoryBodyStatisticsFragment
          ...PrismicServiceCategoryBodyTestimonialFragment
          ...PrismicServiceCategoryBodyImagesFragment
          ...PrismicServiceCategoryBodyQuickLinksFragment
        }
      }
    }
  }
`;
