import React from 'react';
import { graphql } from 'gatsby';
import { mergePrismicPreviewData } from 'gatsby-source-prismic';
import { Layout, Slice } from 'components';

const IS_BROWSER = typeof window !== 'undefined';

export const Project = ({ location, data: staticData }) => {
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__;
  const data = mergePrismicPreviewData({ staticData, previewData });
  const { prismicProject } = data;
  const { data: pageData } = prismicProject;
  const {
    body: sliceData,
    meta_title: metaTitle,
    meta_description: metaDesc,
    open_graph_image: ogImage,
    schema,
  } = pageData;
  const seo = {
    title: metaTitle?.text,
    desc: metaDesc?.text,
    banner: ogImage?.localFile?.childImageSharp?.fixed?.src,
    schema: schema?.text,
  };
  return (
    <Layout location={location} seo={seo}>
      {sliceData?.map((slice) => (
        <Slice key={slice.id} data={slice} context={pageData} />
      ))}
    </Layout>
  );
};

export default Project;

export const projectQuery = graphql`
  query ProjectBySlug($uid: String!) {
    prismicProject(uid: { eq: $uid }) {
      id
      uid
      prismicId
      data {
        title {
          text
        }
        category {
          uid
          type
          document {
            ... on PrismicProjectCategory {
              id
              data {
                title {
                  text
                }
              }
            }
          }
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url
        }
        schema {
          text
        }
        logo {
          alt
          localFile {
            childImageSharp {
              fluid(maxWidth: 1024) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        body {
          ...PrismicProjectBodyTextFragment
          ...PrismicProjectBodyHeadingImageFragment
          ...PrismicProjectBodyImagesFragment
          ...PrismicProjectBodyVideoFragment
          ...PrismicProjectBodyStatisticsFragment
          ...PrismicProjectBodyQuoteFragment
          ...PrismicProjectBodyFeaturedProjectsFragment
          ...PrismicProjectBodyQuickLinksFragment
          ...PrismicProjectBodyFeaturesFragment
        }
      }
    }
  }
`;
